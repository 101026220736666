import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {Link} from 'react-router-dom';
import Timeline, {TimelineHeaders, SidebarHeader, DateHeader, TimelineMarkers, TodayMarker, CursorMarker} from 'react-calendar-timeline'
import moment from 'moment';
import 'moment-timezone';
import {getDrivers} from "../Drivers/DriverFunctions";
import getCustomers from "../Customers/CustomerFunctions";
import getLocations from "../Locations/LocationFunctions";
import {getEquipment} from "../Equipment/EquipmentFunctions";
import LoggedTrip from "../Loads/LoggedTrip"
import {toast, ToastContainer} from "react-toastify";
import {DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, UncontrolledButtonDropdown} from "reactstrap";
import LoadDisplay from "./LoadDisplay";

moment.tz.setDefault("America/Halifax");

class LoadsTimeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            equipment: {},
            equipment_sort: [],
            customers: {},
            customers_sort: [],
            loading: true,
            loads: {},
            loads_sort: [],
            locations: [],
            locations_sort: {},
            customer_id: null,
            vehicle_id: null,
            origin: null,
            destination: null,
            driver_id: null,
            drivers: {},
            drivers_sort: {},
            group: [],
            templates: [],
            load_status: "Preload",
            startDate: moment().subtract(7, 'week').startOf('day'),
            endDate: moment().add(6, 'days'),
            sort: (localStorage.getItem('sort2') != null ? localStorage.getItem('sort2') : "load_id"),
            sort_order: (localStorage.getItem('sort_order2') != null ? localStorage.getItem('sort_order2') * 1 : -1),
            status: null,
            statuses: {0: "Draft", 1: "Scheduled", 2: "Started", 3: "Completed", 4: "Billed"},
            selected_log_id: null,
            groups: [{"id": 0, "title": "UNASSIGNED", groupProps: {style: {background: "#000",}}}, {"id": 28, "title": ""}, {"id": 104584, "title": ""}, {
                "id": 104592,
                "title": ""
            }, {
                "id": 37,
                "title": ""
            }, {"id": 104572, "title": ""}, {"id": 8, "title": ""}, {"id": 27, "title": ""}, {
                "id": 15,
                "title": ""
            }],
            items: [],
            load_id: false,
            load_modal: false
        };
        this.toggle_load = this.toggle_load.bind(this);
        this.update_load = this.update_load.bind(this);
    }

    componentDidMount() {
        document.title = "Timeline | Jeremie Cormier Trucking Ltd";
        var self = this;
        self.setState({load_status: "Getting Customers"});
        getCustomers('list',function (customers) {
            self.setState({load_status: "Getting Drivers"});
            getDrivers(function (drivers) {
                self.setState({load_status: "Equipment Customers"});
                getEquipment(0, function (equipment) {
                    self.setState({load_status: "Getting Locations"});
                    getLocations(function (location_response) {
                        var customers_sort = [];
                        for (const customer_id of Object.keys(customers)) {
                            customers_sort.push(customers[customer_id]);
                        }
                        var customers_sort = customers_sort.sort(function (a, b) {
                            if (a.name < b.name) {
                                return -1
                            } else {
                                return 1
                            }
                        });
                        self.setState({
                            customers: customers,
                            equipment: equipment,
                            locations: location_response.locations,
                            locations_sort: location_response.locations_sort,
                            drivers: drivers,
                            customers_sort: customers_sort,
                            load_status: "Preloads done"
                        }, function () {
                            this.update_groups();
                            this.update_load();
                        });
                    });
                });
            });
        });
    }

    update_groups() {
        this.setState({load_status: "Setting Groups"});
        var drivers_sort = [];
        var drivers = this.state.drivers;
        for (const driver_id of Object.keys(drivers)) {
            if (drivers[driver_id].status != 0) {
                drivers[driver_id].first_name = drivers[driver_id].first_name[0].toUpperCase() + drivers[driver_id].first_name.slice(1).toLowerCase();
                drivers_sort.push(drivers[driver_id]);
            }
        }
        var drivers_sort = drivers_sort.sort(function (a, b) {
            if (a.first_name < b.first_name) {
                return -1
            } else {
                return 1
            }
        });
        var groups = [{
            "id": 0, "title": "UNASSIGNED", groupProps: {
                style: {
                    background: "#000",
                }
            }
        }];
        for (const driver of drivers_sort) {
            if (this.state.driver_id == null || this.state.driver_id == driver.driver_id || (typeof this.state.driver_id == "string" && String("-" + this.state.driver_id + "-").includes("-" + driver.driver_id + "-"))) {
                groups.push({id: driver.driver_id, title: driver.first_name + " " + driver.last_name});
            }
        }
        this.setState({
            groups: groups,
            drivers_sort: drivers_sort,
            load_status: "Groups Loaded"
        });
    }

    toggle_load = () => {
        this.setState({
            load_modal: !this.state.load_modal
        });
        if (this.state.load_modal) {
            this.update_load()
        }
    };

    update_load() {
        var self = this;
        self.setState({
            loading: true
        });
        axios.defaults.withCredentials = true;
        self.setState({load_status: "Getting Loads"});
        axios.get(API_ROOT + "/loads/?includeTrips=true&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&status=" + self.state.status)
            .then(function (response) {
                var items = [];
                for (var load_id in response.data) {
                    var load = response.data[load_id];
                    var background = "#f8f9fa";
                    var color = "#000;"
                    if (load.status == 0) { // Draft
                        //bg-light
                        background = "#ccc";
                    } else if (load.status == 1) { // scheduled
                        //bg-info
                        background = "#23b7e5";
                    } else if (load.status == 2) { // Started
                        //bg-warning
                        background = "#e46c00";
                    } else if (load.status == 3) { // Completed
                        //bg-success
                        background = "#00d25b";
                    } else if (load.status == 4) { // Billed
                        //bg-dark
                        background = "#f532e5";
                        color = "#fff"
                    }  else if (load.status == 5) { // Billed & Emailed
                        //bg-dark
                        background = "#00cdcd";
                        color = "#fff"
                    }
                    var segments = [];
                    var new_segment = -1;
                    for (var segment_num in load.segments) {
                        if (segment_num > 0 && load.segments[segment_num].driver_id == segments[new_segment].driver_id) {
                            segments[new_segment].end = moment(load.segments[segment_num].start).add((load.segments[segment_num].duration_hrs + load.segments[segment_num].wait_hrs), 'hours');
                        } else {
                            new_segment++;
                            var segment = load.segments[segment_num];
                            segment.end = moment(load.segments[segment_num].start).add((load.segments[segment_num].duration_hrs + load.segments[segment_num].wait_hrs), 'hours');
                            segments[new_segment] = segment;
                        }
                    }
                    for (var segment_num in segments) {
                        var segment = segments[segment_num];
                        items.push(
                            {
                                id: load_id + '-' + segment_num,
                                group: (segment.driver_id == null ? 0 : segment.driver_id),
                                title: load_id + (typeof self.state.customers[load.customer_id] == "undefined" ? "" : ": " + self.state.customers[load.customer_id].name),
                                start_time: moment(segment.start),
                                end_time: segment.end,
                                canResize: false,
                                canChangeGroup: true,
                                num_segments: segments.length,
                                canMove: (load.status != 4),
                                itemProps: {
                                    style: {
                                        color: color,
                                        background: background,
                                        height: "5px",
                                        lineHeight: "5px",
                                        padding: "0px",
                                        borderRadius: "5px",
                                        zIndex: "9999"
                                    }
                                },
                            }
                        )
                    }
                }
                self.setState({load_status: "Getting Logs"});
                axios.get(`${API_ROOT}/drivers/log`, {withCredentials: true})
                    .then(function (ajax_response) {
                        for (var id in ajax_response.data) {
                            var load = ajax_response.data[id];
                            items.push(
                                {
                                    id: "l" + load.drivers_logged_id,
                                    group: load.driver_id,
                                    canResize: false,
                                    canChangeGroup: false,
                                    canMove: false,
                                    start_time: moment(load.in_time).utc(),
                                    end_time: (load.out_time == null ? moment() : moment(load.out_time).utc()),
                                    title: (typeof self.state.equipment[load.equipment_id] == "undefined" ? "" : self.state.equipment[load.equipment_id].name + ": ") + moment(load.in_time).format("HH:mm") + " - " + (load.out_time == null ? "_" : moment(load.out_time).format("HH:mm")),
                                    itemProps: {
                                        style: {
                                            color: "#fff",
                                            background: "#5f0095",
                                            height: "5px",
                                            lineHeight: "5px",
                                            padding: "0px",
                                            border: "0px"
                                        }
                                    }
                                }
                            )
                        }
                        self.setState({
                                items: items,
                                loading: false,
                            load_status: "Ready"
                            }
                        );
                    })
                    .catch(function (error) {
                        response({});
                    });
                getLocations(function (location_response) {
                    self.setState({
                        locations: location_response.locations,
                        locations_sort: location_response.locations_sort
                    })
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    triggerShiftCreateModal = (groupId, time, event) => {
        if (groupId.substring(0, 1) != "l") {
            this.openLoad(groupId.slice(0, groupId.indexOf("-")));
        } else {
            this.setState({
                selected_log_id: groupId.replace("l", "")
            }, function () {
                this.setState({
                    selected_log_id: null
                })
            });
        }
    }

    openLoad = (load_id) => {
        this.setState({
            load_id: load_id,
            load_modal: true
        })
    }

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        this.setState({
            [name]: value,
        }, this.update_groups);
    }

    handleItemMove = (load_id, dragTime, group_id) => {
        const {items, groups} = this.state;
        const group = groups[group_id];
        if (items.find(o => o.id === load_id).num_segments == 1) {
            this.setState({
                items: items.map(item =>
                    item.id === load_id
                        ? Object.assign({}, item, {
                            start: dragTime,
                            end: dragTime + (item.end - item.start),
                            group: group.id
                        })
                        : item
                )
            });
            var self = this;
            axios.defaults.withCredentials = true;
            load_id = load_id.slice(0, load_id.indexOf("-"));
            axios.post(API_ROOT + '/loads/' + load_id, {
                driver_id: (group.id == 0 ? null : group.id),
                labour_rate_miles: parseFloat(typeof this.state.drivers[group.id] == "undefined" ? 0 : this.state.drivers[group.id].rate_miles),
                labour_rate_hr: parseFloat(typeof this.state.drivers[group.id] == "undefined" ? 0 : this.state.drivers[group.id].rate_hr)
            })
                .then(function (response) {
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                        }
                    }
                });
        } else {
            Swal("Error", "This load can't be dragged since it has trips from multiple drivers", "error");
        }
    };


    render() {
        var active_driver = "";
        if (this.state.driver_id == null) {
            active_driver = "All Drivers";
        } else {
            active_driver = this.state.drivers[this.state.driver_id].first_name + " " + this.state.drivers[this.state.driver_id].last_name;
        }

        return (
            <>
            <ContentWrapper>
                <div className="content-heading mb-2">
                    <div>Driver & Loads Timeline</div>
                    <div className="ml-auto">
                        <div style={{fontSize: "12px"}}>
                            Status:<br/>{this.state.load_status}
                        </div>
                    </div>
                </div>
                <UncontrolledButtonDropdown className="mr-1">
                    <DropdownToggle color="primary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                        {active_driver}
                    </DropdownToggle>
                    <DropdownMenu style={{zIndex: 9999}}>
                        <DropdownItem key="null" onClick={() => this.updateValue(false, 'driver_id', null)}>All
                            Drivers</DropdownItem>
                        <DropdownItem divider/>
                        {Object.keys(this.state.drivers_sort).map((key, i) => {
                            return (<DropdownItem key={key}
                                                  onClick={() => this.updateValue(false, 'driver_id', this.state.drivers_sort[key].driver_id)}>{this.state.drivers_sort[key].first_name} {this.state.drivers_sort[key].last_name}</DropdownItem>)
                        })}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                <div
                    className={(this.state.loading ? "mt-1 card card-default whirl traditional" : "mt-1 card card-default")}>
                    <ToastContainer style={{zIndex: '1099 !important'}} limit={1} containerStyle={{zIndex: '1099 !important'}} toastOptions={{style: {zIndex: '1099 !important'}}}/>

                    <div>
                        <Timeline groups={this.state.groups} items={this.state.items}
                                  defaultTimeStart={moment().add(-12, 'hour')}
                                  defaultTimeEnd={moment().add(12, 'hour')}
                                  stackItems
                                  canMove={true}
                                  onItemClick={this.triggerShiftCreateModal}
                                  itemTouchSendsClick={true}
                                  onItemMove={this.handleItemMove}
                                  howCursorLine
                        >
                            <TimelineHeaders className="sticky">
                                <SidebarHeader>
                                    {({getRootProps}) => {
                                        return <div {...getRootProps()}></div>;
                                    }}
                                </SidebarHeader>
                                <DateHeader unit="primaryHeader"/>
                                <DateHeader/>
                            </TimelineHeaders>
                            <TimelineMarkers>
                                <TodayMarker/>
                                <CursorMarker/>
                            </TimelineMarkers>
                        </Timeline>
                    </div>
                </div>
                <LoggedTrip history={this.props.history} log_id={this.state.selected_log_id} drivers={this.state.drivers} locations={this.state.locations}
                            equipment={this.state.equipment} update_load={this.update_load} openLoad={this.openLoad}/>
                <Modal isOpen={this.state.load_modal} fade={false} toggle={() => {
                    this.toggle_load()
                }} fullscreen size="xl">

                    <div className="modal-header">
                        <div className="modal-title">
                            <div className="h3 mb-0">
                                Load # {this.state.load_id}
                            </div>
                        </div>
                        <Link to={"../loads/" + this.state.load_id} title="Open in new window"><i className="fas fa-external-link-alt"></i></Link>
                    </div>
                    <ModalBody className="pt-0">
                        <LoadDisplay {...this.state} props={this.props} load_id={this.state.load_id}/>
                    </ModalBody>
                </Modal>
            </ContentWrapper>
            </>
        );
    }
}

export default (LoadsTimeline);