import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input, InputGroup, InputGroupAddon
} from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import moment from 'moment';
import 'moment-timezone';
import getCustomers from '../Customers/CustomerFunctions';
import {getDrivers} from '../Drivers/DriverFunctions';
import {getEquipment} from "../Equipment/EquipmentFunctions";

moment.tz.setDefault("America/Halifax");

class TemplateCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            processing: false,
            loading: true,
            template: {
                name: "",
                tender_date: moment(),
                customer_id: null,
                driver_id: null,
                cust_load_num: "",
                description: "",
                vehicle_id: null,
                segments: [],
                rate_miles: 0,
                rate_fixed: 0,
                rate_fixed_fsc: 0,
                rate_other: 0,
                rate_tolls: 0,
                rate_tolls_billable: 0,
                rate_hr: 0,
                rate_wait: 0,
                rate_drops: 0,
                tax_miles: 0,
                tax_fixed: 0,
                tax_fixed_fsc: 0,
                tax_other: 0,
                tax_drops: 0,
                tax_wait: 0,
                tax_hr: 0,
                qty_drops: 0,
                qty_other: 0,
                qty_fsc: 0,
                qty_fixed: 0,
                avoid_borderCrossing: 1,
                avoid_minimizeUTurn: 1,
                avoid_ferry: 1
            },
            customers: {},
            customers_sort: [],
            drivers: {},
            drivers_sort: [],
            vehicles: false,
            vehicles_sort: [],
        };
    }

    toggle = async () => {
        if (!this.state.modal) {
            var self = this;
            var template = this.state.template;
            if (typeof this.props.template == "undefined") { // new
                for (let key in this.state.template) {
                    if (typeof this.props[key] != "undefined") {
                        template[key] = this.props[key]
                    }
                }
            } else { // editing
                for (let key in this.props.template) {
                    if (typeof this.state.template[key] != "undefined") {
                        template[key] = this.props.template[key]
                    }
                }
            }
            var customers_sort = this.props.customers_sort;
            var customers = this.props.customers;
            if (typeof customers_sort == "undefined") {
                await getCustomers('list', async function (temp) {
                    customers = temp;
                    customers_sort = [];
                    for (const customer_id of Object.keys(customers)) {
                        customers_sort.push(customers[customer_id]);
                    }
                    customers_sort = customers_sort.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1
                        } else {
                            return 1
                        }
                    });

                });
            }
            var drivers_sort = this.props.drivers_sort;
            var drivers = this.props.drivers;
            if (typeof drivers_sort == "undefined") {
                await getDrivers(async function (temp) {
                    drivers = temp;
                    drivers_sort = [];
                    for (const driver_id of Object.keys(drivers)) {
                        drivers_sort.push({value: drivers[driver_id].driver_id, label: drivers[driver_id].first_name + " " + drivers[driver_id].last_name});
                    }
                    drivers_sort = drivers_sort.sort(function (a, b) {
                        if (a.label < b.label) {
                            return -1
                        } else {
                            return 1
                        }
                    });
                });
            }
            var vehicles_sort = this.state.vehicles_sort;
            var vehicles = this.state.vehicles;
            if (vehicles == false) {
                await getEquipment(0, async function (temp) {
                    vehicles = temp;
                    vehicles_sort = [];
                    for (const vehicle_id of Object.keys(vehicles)) {
                        if (vehicles[vehicle_id].is_vehicle == 1) {
                            vehicles_sort.push({
                                vehicle_id: vehicles[vehicle_id].equipment_id,
                                name: vehicles[vehicle_id].name
                            });
                        }
                    }
                    vehicles_sort = vehicles_sort.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1
                        } else {
                            return 1
                        }
                    });
                });
            }
            this.setState({
                customers_sort: customers_sort,
                customers: customers,
                drivers_sort: drivers_sort,
                drivers: drivers,
                template: template,
                vehicles_sort: vehicles_sort,
                vehicles: vehicles,
                loading: false
            });
        }
        this.setState({
            modal: !this.state.modal,
        });
    };

    updateField = (name, value) => {
        if (value === true) {
            value = 1
        } else if (value === false) {
            value = 0
        } else if (typeof value.target != "undefined") {
            value = value.target.value;
        }
        var template = this.state.template;
        template[name] = value
        console.log(template)
        this.setState({
            template: template
        });
    };

    createTemplate = async event => {
        var self = this;
        if (this.state.template.name == "") {
            Swal("Error", "Please enter a template name", "error");
        } else {
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/loads/' + this.props.load_id + '/template', this.state.template)
                .then(function (response) {
                    self.toggle();
                    Swal("Done!", "The template has been created and can be used from the \"Loads\" page.", "success");
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                        }
                    }
                });
        }
    };

    editTemplate = async event => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/loads/template/' + this.props.template_id, this.state.template)
            .then(function (response) {
                self.toggle();
                if (typeof self.props.updateTemplates != "undefined") {
                    self.props.updateTemplates();
                }
                Swal("Done!", "Changes saved", "success");
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                }
            });
    };

    render() {
        return (
            <>
                <div className={(typeof this.props.template_id == "undefined" ? "btn btn-xs btn-light ml-1 float-right mt-1" : "d-none")} title="Create a Template"
                     onClick={this.toggle}>
                    <i className="far fa-clipboard"></i> Create Template
                </div>
                <div className={(typeof this.props.template_id == "undefined" ? "d-none" : "btn btn-light")} onClick={this.toggle}>
                    {(typeof this.props.template_id == "undefined" ? "" : this.props.template.name)}
                </div>
                <Modal isOpen={this.state.modal} fade={false} toggle={this.toggle} size="lg">
                    <ModalHeader>
                        <div className="h3 mb-0">{(typeof this.props.template_id == "undefined" ? "Create" : "Edit")} Template</div>
                    </ModalHeader>
                    <ModalBody className={(this.state.loading ? "whirl traditional" : "")}>
                        <Form>
                            <div className={(typeof this.props.template_id == "undefined" ? "d-none" : "alert alert-info")}>
                                <i className="fas fa-info-circle mr-1"></i> Editing template details will only apply to future loads created from this template. Existing loads will
                                not be changed.
                            </div>
                            <FormGroup row className="mb-1">
                                <Label sm={4}>Template Name:</Label>
                                <Col sm={8}>
                                    <Input type="text" size="sm" name="name" value={this.state.template.name}
                                           onChange={(event) => this.updateField("name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="mb-1">
                                <Label sm={4}>Description:</Label>
                                <Col sm={8}>
                                    <Input type="textarea" size="sm" name="description" value={this.state.template.description}
                                           onChange={(event) => this.updateField("description", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="mb-1">
                                <Label sm={4}>Cust. Load #:</Label>
                                <Col sm={8}>
                                    <Input type="textarea" size="sm" name="cust_load_num" value={this.state.template.cust_load_num}
                                           onChange={(event) => this.updateField("cust_load_num", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="mb-1">
                                <Label sm={4}>Customer:</Label>
                                <Col sm={8}>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                            {(this.state.loading || this.state.template.customer_id == null ? "(not set)" : this.state.customers[this.state.template.customer_id].name)}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem key="null" onClick={() => this.updateField('customer_id', null)}>(not set)</DropdownItem>
                                            <DropdownItem divider/>
                                            {Object.keys(this.state.customers_sort).map((key, i) => {
                                                return (<DropdownItem key={key}
                                                                      onClick={() => this.updateField('customer_id', this.state.customers_sort[key].customer_id)}>{this.state.customers_sort[key].name}</DropdownItem>)
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="mb-1">
                                <Label sm={4}>Driver:</Label>
                                <Col sm={8}>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                            {(this.state.loading || this.state.template.driver_id == null ? "(not set)" : this.state.drivers[this.state.template.driver_id].first_name + " " + this.state.drivers[this.state.template.driver_id].last_name)}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem key="null" onClick={() => this.updateField('driver_id', null)}>(not set)</DropdownItem>
                                            <DropdownItem divider/>
                                            {Object.keys(this.state.drivers_sort).map((key, i) => {
                                                return (<DropdownItem key={key}
                                                                      onClick={() => this.updateField('driver_id', this.state.drivers_sort[key].value)}>{this.state.drivers_sort[key].label}</DropdownItem>)
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="mb-1">
                                <Label sm={4}>Vehicles:</Label>
                                <Col sm={8}>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                            {(this.state.loading || this.state.template.vehicle_id == null || typeof this.state.vehicles[this.state.template.vehicle_id] == "undefined" ? "(not set)" : this.state.vehicles[this.state.template.vehicle_id].name)}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem key="null" onClick={() => this.updateField('vehicle_id', null)}>(not set)</DropdownItem>
                                            <DropdownItem divider/>
                                            {Object.keys(this.state.vehicles_sort).map((key2, i2) => {
                                                return (<DropdownItem key={key2}
                                                                      onClick={() => this.updateField('vehicle_id', this.state.vehicles_sort[key2].vehicle_id)}>{this.state.vehicles_sort[key2].name}</DropdownItem>)
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                            </FormGroup>
                            <div className="alert alert-info">
                                <i className="fas fa-info-circle mr-1"></i> Leave rate cell blank if you would like the rate to be populated from the customer's current rates at
                                the time the
                                load is create. Otherwise, rates defined below will not be changed when a load from the template is created.
                            </div>
                            <table className="table table-sm">
                                <thead>
                                <tr className="thead-light">
                                    <th>Rate Type</th>
                                    <th className="text-right pr-3">Rate</th>
                                    <th className="text-right pr-3">Qty</th>
                                    <th className="text-right pr-3">Tax</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        Fixed Rate
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" className="text-right" name="rate_fixed" value={this.state.template.rate_fixed}
                                                   onChange={(event) => this.updateField("rate_fixed", event)}/>
                                        </InputGroup>
                                    </td>
                                    <td>
                                        <Input type="text" className="text-right" name="qty_fixed" value={this.state.template.qty_fixed}
                                               onChange={(event) => this.updateField("qty_fixed", event)}/>
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_fixed" value={this.state.template.tax_fixed}
                                                   onChange={(event) => this.updateField("tax_fixed", event)}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Rate per Mile
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_miles" className="text-right" value={this.state.template.rate_miles}
                                                   onChange={(event) => this.updateField("rate_miles", event)}/>
                                        </InputGroup>
                                    </td>
                                    <td className="text-right pr-3"></td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_miles" value={this.state.template.tax_miles}
                                                   onChange={(event) => this.updateField("tax_miles", event)}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Driving Rate/Hour
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_hr" className="text-right" value={this.state.template.rate_hr}
                                                   onChange={(event) => this.updateField("rate_hr", event)}/>
                                            <InputGroupAddon addonType="append">/hr</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td className="text-right pr-3"></td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_hr" value={this.state.template.tax_hr}
                                                   onChange={(event) => this.updateField("tax_hr", event)}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Wait Rate/Hour
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_wait" className="text-right" value={this.state.template.rate_wait}
                                                   onChange={(event) => this.updateField("rate_wait", event)}/>
                                            <InputGroupAddon addonType="append">/hr</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td className="text-right pr-3"></td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_wait" value={this.state.template.tax_wait}
                                                   onChange={(event) => this.updateField("tax_wait", event)}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Fixed Fuel Surcharge
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_fixed_fsc" className="text-right" value={this.state.template.rate_fixed_fsc}
                                                   onChange={(event) => this.updateField("rate_fixed_fsc", event)}/>
                                        </InputGroup>
                                    </td>
                                    <td>
                                        <Input type="text" name="qty_fsc" className="text-right" value={this.state.template.qty_fsc}
                                               onChange={(event) => this.updateField("qty_fsc", event)}/>
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_fixed_fsc" value={this.state.template.tax_fixed_fsc}
                                                   onChange={(event) => this.updateField("tax_fixed_fsc", event)}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Tolls
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_tolls_billable" className="text-right"
                                                   value={this.state.template.rate_tolls_billable}
                                                   oonChange={(event) => this.updateField("rate_tolls_billable", event)}/>
                                        </InputGroup>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        Drop Fee
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_drops" className="text-right" value={this.state.template.rate_drops}
                                                   onChange={(event) => this.updateField("rate_drops", event)}/>
                                        </InputGroup>
                                    </td>
                                    <td>
                                        <Input type="text" name="qty_drops" className="text-right" value={this.state.template.qty_drops}
                                               onChange={(event) => this.updateField("qty_drops", event)}/>
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_drops" value={this.state.template.tax_drops}
                                                   onChange={(event) => this.updateField("tax_drops", event)}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Other
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="rate_other" className="text-right" value={this.state.template.rate_other}
                                                   onChange={(event) => this.updateField("rate_other", event)}/>
                                        </InputGroup>
                                    </td>
                                    <td>
                                        <Input type="text" name="qty_other" className="text-right" value={this.state.template.qty_other}
                                               onChange={(event) => this.updateField("qty_other", event)}/>
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <Input type="text" className="text-right" name="tax_other" value={this.state.template.tax_other}
                                                   onChange={(event) => this.updateField("tax_other", event)}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" className={(typeof this.props.template_id == "undefined" ? "" : "d-none")} onClick={this.createTemplate}><i
                            className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Create New
                            Template</Button>
                        <Button color="primary" className={(typeof this.props.template_id != "undefined" ? "" : "d-none")} onClick={this.editTemplate}><i
                            className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Save</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    };
}

export default (TemplateCreate);
